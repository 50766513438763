// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_vN";
export var lbContainerInner = "z_vP";
export var movingForwards = "z_vQ";
export var movingForwardsOther = "z_vR";
export var movingBackwards = "z_vS";
export var movingBackwardsOther = "z_vT";
export var lbImage = "z_vV";
export var lbOtherImage = "z_vW";
export var prevButton = "z_vX";
export var nextButton = "z_vY";
export var closing = "z_vZ";
export var appear = "z_v0";